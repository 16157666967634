"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScoobyAPI = void 0;
const FirebaseAPI_1 = require("./FirebaseAPI");
const lodash_1 = require("lodash");
class ScoobyAPI extends FirebaseAPI_1.FirebaseAPI {
    constructor() {
        const scoobyConfig = {
            apiKey: "AIzaSyA3Yd6-H5911_W0gFmFDnx0VcqfRP1iCOA",
            authDomain: "uat-scooby.firebaseapp.com",
            databaseURL: "https://uat-scooby-default-rtdb.firebaseio.com",
            projectId: "uat-scooby",
            storageBucket: "uat-scooby.appspot.com",
            messagingSenderId: "948531876000",
            appId: "1:948531876000:web:420c07932f44936c2eb9c5"
        };
        super(scoobyConfig, "scooby");
    }
    sendOTP({ code, phone }) {
        return __awaiter(this, void 0, void 0, function* () {
            const payload = {
                code,
                phone,
            };
            const results = yield this.callRPC("RPC/secureSendOTP", payload);
            return Boolean((0, lodash_1.get)(results, "result", false));
        });
    }
    verifyOTP({ code, phone, otp }) {
        return __awaiter(this, void 0, void 0, function* () {
            const payload = {
                code,
                phone,
                otp,
            };
            const results = yield this.callRPC("RPC/secureVerifyOTP", payload);
            const success = (0, lodash_1.get)(results, "result", false);
            if (!success)
                return false;
            const token = (0, lodash_1.get)(results, "token", "");
            const decodedToken = FirebaseAPI_1.FirebaseAPI.decodeJWT(token);
            const { orgs = [], envs = {} } = (0, lodash_1.get)(decodedToken, "claims", {});
            const creds = yield this.signInWithCustomToken(token);
            const newToken = yield creds.user.getIdToken(true);
            return { orgs, envs, newToken, token };
        });
    }
}
exports.ScoobyAPI = ScoobyAPI;
